import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#5A6872',
			mainImportant: '#5A6872  !important',
			white: '#FFFFFF',
		},
	},
});

export default theme;