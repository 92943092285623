import React from 'react';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NoMatch = function () {
    const location = useLocation();

    return (
        <Box p={3}>
            <Typography variant="h6" mb={2}>
                Kein Match für
                {' '}
                <code>{location.pathname}</code>
            </Typography>
        </Box>
    );
};

export default NoMatch;
