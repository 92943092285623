import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import useAuth from '../../hooks/useAuth.js';
import Header from '../header/Header.jsx';
import Version from '../version/Version.jsx';

const Layout = () => {
    const { auth } = useAuth();

    return (
        <Box sx={{ background: '#FAFAFA', minHeight: '100vh' }}>
            <CssBaseline />
            {auth?.token ? (
              	<>
                  <Box m={3} sx={{
                    marginLeft: '100px !important'
                  }}>
                      <Header />
                    
                  </Box>
                  <Box pt={12} pb={4} m={3}>
                      <Outlet />
                  </Box>
              </>
            )
                : (
                    <Box>
                        <Outlet />
                    </Box>
                )}
        <Version />
        </Box>
    );
};

export default Layout;
