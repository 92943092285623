import React from 'react';
import { Typography, Box} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'
import Icon from '../assets/icons/Icon.png'

const EluDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const eluDetails = location.state;
    
    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate('/uebersicht')}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft" />
                </Box>

                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography  mb={2}
                sx={{
                    font: 'Open Sans',
                    fontWeight: 700,
                    lineHeight: '33.6px',
                    fontSize: '24px'
                }}
            >
                eLU
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Sportart
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            Agility
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Turniername / Datum
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {eluDetails?.trn_nam} / {eluDetails?.trn_dat}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '16.8px',
                                fontSize: '12px',
                                color: '#5A6872'
                            }}
                        >
                            Ausrichtender Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography 
                            sx={{
                                font: 'Open Sans',
                                fontWeight: 700,
                                lineHeight: '22.4px',
                                fontSize: '16px'
                            }}
                        >
                            {eluDetails?.trn_ver}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            
            {(() => {if(eluDetails?.teamdata) {return (<>
            <Typography  mb={2}
                sx={{
                    font: 'Open Sans',
                    fontWeight: 700,
                    lineHeight: '33.6px',
                    fontSize: '24px'
                }}
            >
                Läufe ({eluDetails?.teamdata.length})
            </Typography>
                
            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                {
                    eluDetails?.teamdata?.map((data, index) => 
                    <Box p={2}>
                        <Box>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Hundename / Identnr
                            </Typography>
                        </Box>
    
                        <Box mb={2}>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '22.4px',
                                    fontSize: '16px'
                                }}
                            >
                                {data?.tea_hnm} / {data?.tea_chp}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Gesamtpunkte
                            </Typography>
                        </Box>
    
                        <Box mb={2}>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '22.4px',
                                    fontSize: '16px'
                                }}
                            >
                                {data?.prc_gpk}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                PLatzierung
                            </Typography>
                        </Box>
    
                        <Box mb={2}>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '22.4px',
                                    fontSize: '16px'
                                }}
                            >
                                {data?.prc_rng}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography 
                                sx={{
                                    font: 'Open Sans',
                                    fontWeight: 700,
                                    lineHeight: '16.8px',
                                    fontSize: '12px',
                                    color: '#5A6872'
                                }}
                            >
                                Sonstige Daten
                            </Typography>
                        </Box>
    
                        <Box mb={2}>
                            <List dense>
                                <ListItem><ListItemText disableGutters primary="ID des HF/Hund-Teams" secondary={data?.tea_idn} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Leistungsurkunden-Nr." secondary={data?.tea_lun} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Chipnummer" secondary={data?.tea_chp} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Hundename" secondary={data?.tea_hnm} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Hunderasse" secondary={data?.tea_ras} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Verband des Hundes (LU)" secondary={data?.tea_vhd} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Mitgliedsnummer des HF" secondary={data?.tea_mgn} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Name des HF" secondary={data?.tea_nam} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Vorname des HF" secondary={data?.tea_vor} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Verband des HF" secondary={data?.tea_vhf} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Geburtsdatum Hundeführer" secondary={data?.tea_geb} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Land des HF" secondary={data?.tea_lnd} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Hundegröße des Teams)" secondary={data?.tea_siz} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="tea_par" secondary={data?.tea_par} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Leistungsklasse" secondary={data?.tea_lkl} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Name des LR des Parcours" secondary={data?.prc_ric} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Nr des LR des Parcours" secondary={data?.prc_rin} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Verband des LR des Parcours" secondary={data?.prc_riv} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Parcours-Nummer" secondary={data?.prc_idn} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Benennung des Parcours" secondary={data?.prc_nam} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Anzahl der Starter im Parcours" secondary={data?.prc_sta} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="prc_wrt" secondary={data?.prc_wrt} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Länge Parcour" secondary={data?.prc_lgp} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Sollzeit" secondary={data?.prc_soz} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Laufzeit in 1/100 Sekunden" secondary={data?.prc_tim} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Zeitfehlerpunkte" secondary={data?.prc_zfp} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Anzahl der Fehler im Parcours" secondary={data?.prc_feh} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Anzahl der Verweigerungen" secondary={data?.prc_ver} /></ListItem>
                                <ListItem><ListItemText disableGutters primary="Geschwindigkeit in 1/100 Meter pro Sekunde" secondary={data?.prc_spe} /></ListItem>
                            </List>
                        </Box>
                    </Box>
                    )
                }
            </Box>
            </>)}})()}
            
        </Box>
    )
}

export default EluDetails;