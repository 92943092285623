import React from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../../hooks/useAuth';

const CheckIfLoged = function () {
	const { auth } = useAuth();
	const location = useLocation();

	if (auth?.token) {
		const { exp } = jwtDecode(auth?.token);
		if (exp > (new Date().getTime() + 1) / 1000) {

			return (<Navigate to="/uebersicht" state={{ from: location }} replace />);
		}
	}

	return (<Outlet />);

};

export default CheckIfLoged;
