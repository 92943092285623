import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const Redirect = function () {
	const { auth } = useAuth();

	return <Navigate to={auth?.token ? '/uebersicht' : '/login'} />;
};

export default Redirect;
