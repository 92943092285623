import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Form from '../common/form/Form.jsx';
import Version from '../version/Version.jsx';

import { resetpw } from './auth-api.js';

import Vector from '../../assets/icons/Vector.png'

const Resetpw = function () {

	const [err, setErr] = useState('');
	const [suc, setSuc] = useState('');
	const navigate = useNavigate();
	
	const [searchParams] = useSearchParams();

	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);
	
	const handleClickShowPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);
	const handleMouseDownPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);
	
	const token = searchParams.get("token");

	const onSubmit = async (passwords) => {
		if(passwords.password !== passwords.passwordrepeat) {
			setSuc('');
			setErr('Passwörter stimmen nicht überein');
			return;
		}
		setErr('');
		passwords.token = token;
		try {
			const { data } = await resetpw(passwords, token);
			setErr('');
			setSuc(data?.message);
		} catch (error) {
			setSuc('');
			setErr(error?.response?.data?.message);
		}
	};

	const formData = [
		{
			label: 'Neues Passwort',
			id: 'password',
			type: 'password',
			required: 'Erforderliches Feld',
			fullWidth: true,
			showPassword: showPassword,
			handleClickShowPassword: handleClickShowPassword,
			handleMouseDownPassword: handleMouseDownPassword,
		},
		{
			label: 'Neues Passwort wiederholen',
			id: 'passwordrepeat',
			type: 'password',
			required: 'Erforderliches Feld',
			fullWidth: true,
			showPassword: showPasswordRepeat,
			handleClickShowPassword: handleClickShowPasswordRepeat,
			handleMouseDownPassword: handleMouseDownPasswordRepeat,
		},
	];
	
	return (
		<Container maxWidth="sm" sx={{ paddingY: '144px', justifyContent: 'center', textAlign: 'center' }}>
			<Typography
				my={4} ml={1}
				sx={{
					font: 'Open Sans',
					fontWeight: 700,
					lineHeight: '36.4px',
					fontSize: '26px',
				}}
			>Neues Passwort vergeben
			</Typography>
			<Box mb={4}>
				<Typography
					sx={{
						font: 'Open Sans',
						fontWeight: 700,
						lineHeight: '16.8px',
						fontSize: '12px',
						color: '#5A6872'
					}}
				>
					Geben Sie Ihr neues Passwort ein.
				</Typography>
			</Box>
			<Form formData={formData} onSubmit={onSubmit} responseError={err} responseSuccess={suc}>
				<Box mt={4}>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{height: '36px'}}
					>
						<Typography
							sx={{
								font: 'Open Sans',
								fontWeight: 700,
								lineHeight: '19.6px',
								fontSize: '14px',
							}}
						>
							Passwort ändern
						</Typography>
					</Button>
				</Box>
			</Form>
			
			<Box mt={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate('/login')}
            >
				<Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>
				
                <Typography
                    sx={{
                        font: 'Open Sans',
                        fontWeight: 700,
                        lineHeight: '25.2px',
                        fontSize: '18px',
                    }}
                >
                    Zurück zum Login
                </Typography>
            </Box>
			<Version />
		</Container>
	);

};

export default Resetpw;
